'use strict';
import 'regenerator-runtime/runtime.js';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';

import { animateCounter } from './components/animate-counter';
import { contactFormSubmission } from './components/marke-form';
import { loadIcons } from './components/icons';
import { BannerSlider, TestimonialsSlider, PartnersSlider, BlogSlider } from './components/sliders';
import { stickyHeader } from './components/sticky-header';

import '../scss/main.scss';

const loadSliders = () => {
  document.querySelectorAll('.banner-slider').forEach((bannerSlider) => {
    new BannerSlider(bannerSlider);
  });
  document.querySelectorAll('.testimonials-slider').forEach((testimonialSlider) => {
    new TestimonialsSlider(testimonialSlider);
  });
  document.querySelectorAll('.partners-slider').forEach((partnersSlider) => {
    new PartnersSlider(partnersSlider);
  });
  document.querySelectorAll('.blog-slider').forEach((blogSlider) => {
    new BlogSlider(blogSlider);
  });
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    stickyHeader();
    document.querySelectorAll('.counter-up').forEach((counter) => {
      animateCounter(counter, 200);
    });
    loadIcons();
    loadSliders();
    contactFormSubmission();
  });
} else {
  stickyHeader();
  document.querySelectorAll('.counter-up').forEach((counter) => {
    animateCounter(counter, 200);
  });
  loadIcons();
  loadSliders();
  contactFormSubmission();
}
